import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface AgentState {
  agentDetails: any;
  loading: boolean;
  error: string | null;
}

const initialState: AgentState = {
  agentDetails: null,
  loading: false,
  error: null,
};

export const getAgent = createAsyncThunk(
  "agent/getAgent",
  async (agentId: number, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://passport.privytrips.com/api/agent/id",
        {
          agent_id: agentId,
        }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAgent.fulfilled, (state, action) => {
        state.agentDetails = action.payload;
        state.loading = false;
      })
      .addCase(getAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default agentSlice.reducer;
