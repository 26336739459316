import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import InfoBadge from "../../../InfoBadge";
import OAFCard from "../../../OAFCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import StatusDialog from "../../../../../components/modal/StatusDialog/StatusDialog";
import React, { useEffect, useState } from "react";
import useAuth from "../../../../../hooks/useAuth";
import {
  approveTipsAction,
  confirmTipsAction,
  confirmTravelProtectionAction,
} from "../../../../../redux/slices/actions/salesActions";

const TipsSection = () => {
  const saleDetails = useSelector(
    (state: RootState) => state.sales.saleDetails
  );
  const { oafs } = useSelector((state: RootState) => state.sales.saleDetails);
  const { role } = useAuth();

  const [status, setStatus] = useState<any>("");
  const selectedOaf = oafs.find((oaf) => {
    if (oaf.tips) {
      const hasDefault = oaf.type === "default";
      const hasTips = oaf.type === "tips";

      if (hasTips) {
        return true;
      }

      if (hasDefault) {
        return true;
      }
    }
    return false;
  });
  useEffect(() => {
    if (selectedOaf) {
      setStatus(selectedOaf.tips![0].status);
      setConfirmed(selectedOaf.tips![0].confirmed);
      setApproved(selectedOaf.tips![0].approved);
    }
  }, [selectedOaf]);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const { agentDetails } = useSelector((state: RootState) => state.agent);
  const dispatch: AppDispatch = useDispatch();
  if (selectedOaf) {
    const show = selectedOaf.tips && selectedOaf.tips.length > 0;
    return show ? (
      <Box>
        <Grid container spacing={1}>
          <Grid item md={12} padding={3}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"} maxHeight={100}>
                {selectedOaf.tips ? (
                  <>
                    <InfoBadge
                      name={"Tips count"}
                      value={`$ ${selectedOaf.tips[0].count}.00`}
                    />
                    {/* Другие компоненты InfoBadge */}
                  </>
                ) : null}
                <InfoBadge
                  name={"Status"}
                  value={status.charAt(0).toUpperCase() + status.slice(1)}
                />
                {agentDetails && (
                  <InfoBadge
                    name={"Created By"}
                    value={agentDetails?.last_name + " " + agentDetails?.name}
                    link={`https://passport.privytrips.com/admin/users/edit/${selectedOaf.createdBy!}`}
                  />
                )}
                <InfoBadge name={"Commission"} value={selectedOaf.commission} />
                <InfoBadge
                  name={"Processed By"}
                  value={selectedOaf.processedBy ?? "-"}
                />
                <InfoBadge
                  name={"Approved By"}
                  value={selectedOaf.approvedBy ?? "-"}
                />
                <InfoBadge name={"OAF ID"} value={selectedOaf.id ?? "-"} />
                {role !== "agent" && saleDetails.status === "inProgress" && (
                  <StatusDialog
                    initialValue={selectedOaf!.tips![0].status}
                    status={status}
                    change={(e: any) => setStatus(e)}
                    productId={selectedOaf!.tips![0].id}
                    type={"tips"}
                  />
                )}
                {saleDetails.status === "inProgress" &&
                  (role === "manager" || role === "ticketing") && (
                    <Button
                      variant={"contained"}
                      disabled={selectedOaf.tips![0].approved}
                      sx={{ marginLeft: 1, height: 50, alignSelf: "center" }}
                      onClick={() => {
                        dispatch(
                          confirmTipsAction({
                            id: selectedOaf.tips![0].id,
                          })
                        );
                        //TO DO MAKE AWAIT / .then()
                        setConfirmed(!confirmed);
                      }}
                    >
                      {confirmed ? "Unconfirm" : "Confirm"}
                      Tips
                    </Button>
                  )}
                {saleDetails.status === "inApproving" &&
                  (role === "manager" || role === "bookkeeping") && (
                    <Button
                      variant={"contained"}
                      sx={{ marginLeft: 1, height: 50, alignSelf: "center" }}
                      onClick={() => {
                        dispatch(
                          approveTipsAction({
                            id: selectedOaf.tips![0].id,
                          })
                        );
                        //TO DO MAKE AWAIT / .then()
                        setApproved(!approved);
                      }}
                    >
                      {approved ? "Reject  " : "Approve "}
                      Tips
                    </Button>
                  )}
              </Box>
              <OAFCard />
            </Box>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <>NO travel Protections </>
    );
  } else {
    return <>NO selectedOaf</>;
  }
};

export default TipsSection;
