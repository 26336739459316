import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import InfoBadge from "../../../InfoBadge";
import OAFCard from "../../../OAFCard";
import AirfareTable from "../../tables/AirfareTable";
import PassengerTabs from "./PassengerTabs/PassengerTabs";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { OAF } from "../../../../../services/openapi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { storePnr } from "../../../../../redux/slices/pnrSlice";
import { PnrRequest } from "../../../../../services/openapi/models/PnrRequest";

const AirfareSection = () => {
  const { oafs } = useSelector((state: RootState) => state.sales.saleDetails);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [curOaf, setCurOaf] = useState<OAF | undefined>();
  const [open, setOpen] = useState(false);
  const [pnrText, setPnrText] = useState("");
  const [responseOpen, setResponseOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const selectedOaf = oafs.find((oaf) => {
      // Проверяем наличие travelProtections
      if (oaf.airfares) {
        // Проверяем, есть ли тип "default"
        const hasDefault = oaf.type === "default";
        if (hasDefault) {
          return true;
        }
      }

      // Если не соответствует условиям, пропускаем
      return false;
    });
    setCurOaf(selectedOaf);
  }, [oafs]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (id) {
      const pnrData: PnrRequest = {
        sale_id: Number(id), // Convert saleId to number
        pnr_dump: pnrText,
      };
      try {
        // @ts-ignore
        const result = await dispatch(storePnr(pnrData)).unwrap();
        setResponseMessage(
          result ? "PNR saved successfully!" : "PNR save failed."
        );
      } catch (error) {
        setResponseMessage("Invalid PNR!");
      }
      setOpen(false);
      setResponseOpen(true);
    }
  };

  const handleConfirm = () => {
    setResponseOpen(false);
    window.location.reload();
  };

  const { agentDetails } = useSelector((state: RootState) => state.agent);
  return curOaf && agentDetails ? (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={12} padding={3}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box display={"flex"} maxHeight={100} sx={{ marginBottom: 10 }}>
              <InfoBadge
                name={"Status"}
                value={
                  curOaf.status.charAt(0).toUpperCase() + curOaf.status.slice(1)
                }
              />
              <InfoBadge
                name={"Created By"}
                value={agentDetails?.last_name + " " + agentDetails?.name}
                link={`https://passport.privytrips.com/admin/users/edit/${curOaf.createdBy!}`}
              />
              <InfoBadge name={"Commission"} value={curOaf.commission} />
              <InfoBadge
                name={"Processed By"}
                value={curOaf.processedBy ?? "-"}
              />
              <InfoBadge
                name={"Approved By"}
                value={curOaf.approvedBy ?? "-"}
              />
              <InfoBadge name={"OAF ID"} value={curOaf.id ?? "-"} />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                Add PNR
              </Button>
            </Box>

            {/*<OAFCard oaf={curOaf} />*/}
          </Box>
          <AirfareTable />
        </Grid>
      </Grid>
      <PassengerTabs />

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ "& .MuiDialog-paper": { width: "1200px" } }}
      >
        <DialogTitle>Add PNR</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="pnr"
            label="PNR"
            type="text"
            fullWidth
            multiline
            rows={30}
            value={pnrText}
            onChange={(e) => setPnrText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={responseOpen}
        onClose={handleConfirm}
        sx={{ "& .MuiDialog-paper": { width: "600px" } }}
      >
        <DialogTitle>Response</DialogTitle>
        <DialogContent>
          <Typography>{responseMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : (
    <>NO Airfares</>
  );
};

export default AirfareSection;
